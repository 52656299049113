import React from 'react';

class FRBCatTXT extends React.Component {
    render() {
        return (
          <div>
          <p>
A complete catalogue of fast radio bursts (FRBs) is now maintained at the
<a href="https://wis-tns.weizmann.ac.il">Transient Name Server (TNS)</a>. Please visit
the TNS for the most up to date FRB population information.  This catalogue
contains the population of fast radio bursts (FRBs) published up to July 2020.
This site is maintained by the FRBCAT team and is no longer actively updated. 
Information for each burst is divided into two categories: observed parameters
from the available data, and derived parameters produced using a model.
Cosmological values are obtained using the Cosmology Calculator (Wright, 2006).
The observed parameters are sometimes either lower or upper limits, due to the
limitations of the data acquisition systems.  Where multiple fits or
measurements of a burst have been made each one is provided as a separate
sub-entry for the FRB.
          </p>
          <p>
   You may use the data presented in this catalogue for publications; however,
we ask that you cite the paper (Petroff et al., 2016) and provide the url
(<a>http://www.frbcat.org</a>). Any issues relating to the use of the catalogue
should be addressed to the FRBCAT team (primary contact: Emily Petroff).
          </p>
          </div>
        );
    }
};

export default FRBCatTXT;

